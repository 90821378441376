
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { CustomerContract } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { CustomerContractStoreMixin } from "@/mixins/Stores/CustomerContractStoreMixin";
import AddButton from "@/components/buttons/AddButton.vue";

const CustomerContractModule = namespace(CustomerContract.store);

@Component({
  components: { AddButton, DefaultTableLayout },
  mixins: [ListMixin],
})
export default class CustomerContractList extends mixins(
  CustomerContractStoreMixin
) {
  @Prop({ required: false, default: "CustomerContractList" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  protected get resource(): string {
    return String(CustomerContract.resource).replace(
      "<customer_id>",
      this.$route?.params?.id
    );
  }

  public loadList(options: any) {
    return this.findAllCustomerContractItemsAction(options);
  }

  protected init() {}
}
